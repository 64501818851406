<template>
  <div style="background-color: #FFFFFF; padding: 10px;" v-loading="loading">
    <el-form :model="form" :rules="rules" ref="form" label-width="220px" >
      <el-form-item label="监测名称" prop="siteName">
        <el-input v-model="form.siteName"></el-input>
      </el-form-item>
      <el-form-item label="数据列表URL链接" prop="dataListUrl">
        <el-input v-model="form.dataListUrl"></el-input>
      </el-form-item>
      <el-form-item label="数据列表xpath表达式" prop="xpathDataList">
        <el-input v-model="form.xpathDataList"></el-input>
      </el-form-item>
      <el-form-item label="标题xpath表达式" prop="xpathTitle">
        <el-input v-model="form.xpathTitle"></el-input>
      </el-form-item>
      <el-form-item label="链接xpath表达式" prop="xpathUrl">
        <el-input v-model="form.xpathUrl"></el-input>
      </el-form-item>
      <el-form-item label="摘要xpath表达式" prop="xpathSummary">
        <el-input v-model="form.xpathSummary"></el-input>
      </el-form-item>
      <el-form-item label="是否需要开启新标签页获取正文">
        <el-switch
          v-model="form.getBodyNeedOpenNewTab"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
      <el-form-item label="新标签页链接xpath表达式" v-if="form.getBodyNeedOpenNewTab" prop="xpathInfo">
        <el-input v-model="form.xpathInfo"></el-input>
      </el-form-item>
      <el-form-item label="正文xpath表达式" prop="xpathBody">
        <el-input v-model="form.xpathBody"></el-input>
      </el-form-item>
      <el-form-item label="作者xpath表达式">
        <el-input v-model="form.xpathAuthor"></el-input>
        <span style="color: gray">不配置不抓取作者信息</span>
      </el-form-item>
      <el-form-item label="地域xpath表达式">
        <el-input v-model="form.xpathLocation"></el-input>
        <span style="color: gray">不配置不抓取地域信息</span>
      </el-form-item>
      <el-form-item label="发布时间xpath表达式" prop="xpathPostDate">
        <el-input v-model="form.xpathPostDate"></el-input>
      </el-form-item>
      <el-form-item label="共抓取几页" prop="getTotalPage">
        <el-input v-model.number="form.getTotalPage"></el-input>
      </el-form-item>
      <el-form-item label="下一页xpath表达式">
        <el-input v-model="form.xpathNextPage"></el-input>
        <span style="color: gray">不配置则不会翻页</span>
      </el-form-item>
      <el-form-item label="抓取间隔">
        <el-select v-model="form.cronText" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否开启">
        <el-switch
          v-model="form.enable"
          active-color="#13ce66"
          inactive-color="#ff4949">
        </el-switch>
      </el-form-item>
    </el-form>
    <div style="text-align: right">
      <el-button type="warning" icon="el-icon-magic-stick" @click="test">立即测试</el-button>
      <el-button v-if="$route.meta.type === 'add'" type="primary" icon="el-icon-plus" @click="add">创建</el-button>
      <el-button v-if="$route.meta.type === 'edit'" type="primary" icon="el-icon-check" @click="update">修改</el-button>
    </div>
    <el-dialog
      title="测试结果"
      :visible.sync="dialogVisible"
      width="50%">
      <span>{{ resultMsg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { add, test, gerInfo, update } from '@/api/spider'
export default {
  name: 'form',
  data () {
    return {
      options: [
        {
          value: 1,
          label: 1
        },
        {
          value: 2,
          label: 2
        },
        {
          value: 3,
          label: 3
        },
        {
          value: 4,
          label: 4
        },
        {
          value: 5,
          label: 5
        },
        {
          value: 6,
          label: 6
        },
        {
          value: 7,
          label: 7
        },
        {
          value: 8,
          label: 8
        },
        {
          value: 9,
          label: 9
        },
        {
          value: 10,
          label: 10
        },
        {
          value: 11,
          label: 11
        },
        {
          value: 12,
          label: 12
        }
      ],
      form: {
        siteName: '',
        dataListUrl: '',
        xpathDataList: '',
        xpathTitle: '',
        xpathUrl: '',
        xpathSummary: '',
        getBodyNeedOpenNewTab: false,
        xpathInfo: '',
        xpathBody: '',
        xpathAuthor: '',
        xpathLocation: '',
        xpathPostDate: '',
        getTotalPage: 1,
        xpathNextPage: '',
        cronText: 1,
        enable: true
      },
      rules: {
        siteName: [
          { required: true, message: '请填写监测名称', trigger: 'blur' }
        ],
        dataListUrl: [
          { required: true, message: '请填写数据列表URL链接', trigger: 'blur' }
        ],
        xpathDataList: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        xpathTitle: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        xpathUrl: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        xpathSummary: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        xpathInfo: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        xpathBody: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        xpathPostDate: [
          { required: true, message: '此xpath为必填项', trigger: 'blur' }
        ],
        getTotalPage: [
          { type: 'number', message: '总页数必须为数字值', trigger: 'change' }
        ]
      },
      loading: false,
      resultMsg: '',
      dialogVisible: false
    }
  },
  methods: {
    async add () {
      var flag = true
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          flag = false
          return false
        }
      })
      if (flag) {
        this.loading = true
        const res = await add(this.form)
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
        this.$router.back()
      }
    },
    async test () {
      var flag = true
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          flag = false
          return false
        }
      })
      if (flag) {
        this.loading = true
        const res = await test(this.form)
        this.dialogVisible = true
        this.resultMsg = res.data
        this.loading = false
      }
    },
    async update () {
      var flag = true
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          flag = false
          return false
        }
      })
      if (flag) {
        this.loading = true
        const res = await update(this.form)
        if (res.code === 0) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
        this.$router.back()
      }
    },
    async showInfo () {
      let id = this.$route.params.id
      const res = await gerInfo({ id: id })
      this.form = res.data
    }
  },
  mounted () {
    if (this.$route.meta.type === 'edit') {
      this.showInfo()
    }
  }
}
</script>

<style scoped>

</style>
